import { render, staticRenderFns } from "./NotificationContentTemplateEdit.vue?vue&type=template&id=486d508c&scoped=true"
import script from "./NotificationContentTemplateEdit.vue?vue&type=script&lang=js"
export * from "./NotificationContentTemplateEdit.vue?vue&type=script&lang=js"
import style0 from "./NotificationContentTemplateEdit.vue?vue&type=style&index=0&id=486d508c&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "486d508c",
  null
  
)

export default component.exports