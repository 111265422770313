<template>
  <GrayBlockContainer class="w-[560px]">
    <div>
      <TitleWithSwitch title="標題" inactiveText="隱藏/顯示" :toggle.sync="shopInfoConfig.enabledTitle" />
      <BaseElFormItem prop="shopInfoConfig.title">
        <BaseElInput v-model="shopInfoConfig.title" placeholder="圖文資訊範本名稱" />
      </BaseElFormItem>
    </div>

    <div>
      <TitleWithSwitch title="店家名稱" inactiveText="隱藏/顯示" :toggle.sync="shopInfoConfig.showShopName" />
      <TitleWithSwitch title="店家電話" inactiveText="隱藏/顯示" :toggle.sync="shopInfoConfig.showShopPhone" />
      <TitleWithSwitch title="店家地址" inactiveText="隱藏/顯示" :toggle.sync="shopInfoConfig.showShopAddress" />
      <BaseElFormItem prop="shopInfoConfig.customShopAddress">
        <BaseElInput v-model="shopInfoConfig.customShopAddress" :disabled="copy" placeholder="請輸入地址" />
        <BaseElCheckbox v-model="copy" @change="copyStoreAddress">套用分店設定的地址</BaseElCheckbox>
      </BaseElFormItem>
      <div v-for="(action, index) in shopInfoConfig.actions" :key="index">
        <TitleWithSwitch
          :title="action.style === 'small' ? '小按鈕' : '大按鈕'"
          inactiveText="隱藏/顯示"
          :toggle.sync="action.enabled"
        />
        <p v-if="action.type === 'googleMap'" class="text-sm text-gray-80 mb-[8px]">
          此按鈕點擊後前往的 google 地圖為「分店設定的地址」
        </p>
        <BaseElFormItem :prop="`shopInfoConfig.actions.${index}.label`">
          <div class="grid grid-cols-2 gap-[8px] mb-[20px]">
            <BaseElInput v-model="action.label" :placeholder="getPlaceholder(action.type)" />
            <BaseElFormItem v-if="action.type === 'openLink'" :prop="`shopInfoConfig.actions.${index}.linkUrl`">
              <BaseElInput v-model="action.linkUrl" placeholder="請輸入連結" />
            </BaseElFormItem>
            <BaseElInput v-else :value="getDisabledText(action.type)" disabled />
          </div>
        </BaseElFormItem>
      </div>
    </div>
  </GrayBlockContainer>
</template>

<script>
import { defineComponent, computed, onMounted, ref } from 'vue'
import GrayBlockContainer from '@/components/Container/GrayBlockContainer.vue'
import TitleWithSwitch from '@/components/TitleWithSwitch.vue'
import { useVModel } from '@vueuse/core'
import { get, set, find } from 'lodash'
import { useShop } from '@/use/shop'
export default defineComponent({
  name: 'ShopInfoNotificationContentTemplate',
  components: { GrayBlockContainer, TitleWithSwitch },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    shopId: {
      type: String,
      default: '',
    },
    isDefault: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const shopInfoConfig = useVModel(props, 'data', emit)
    const { shopAddress } = useShop()
    const copy = ref(false)
    const copyStoreAddress = () => {
      set(shopInfoConfig.value, 'customShopAddress', copy.value ? shopAddress.value : get(shopInfoConfig.value, 'customShopAddress'))
    }
    const getPlaceholder = (type) => {
      switch (type) {
        case 'googleMap':
          return '點擊後：開啟店家地址 google 地圖'
        case 'openLink':
          return '請輸入按鈕文字'
        case 'shopPhone':
          return '點擊後：撥打店家電話'
        default:
          return ''
      }
    }
    const getDisabledText = (type) => {
      switch (type) {
        case 'googleMap':
          return '點擊後：開啟店家地址 google 地圖'
        case 'shopPhone':
          return '點擊後：撥打店家電話'
        default:
          return ''
      }
    }
    onMounted(async () => {
      if (get(props, 'isDefault') && !get(shopInfoConfig.value, 'customShopAddress')) {
        copy.value = true
        set(shopInfoConfig.value, 'customShopAddress', shopAddress.value)
      }
    })
    return {
      shopInfoConfig,
      copy,
      copyStoreAddress,
      getPlaceholder,
      getDisabledText,
    }
  },
})
</script>

  <style lang="postcss" scoped>
::v-deep .el-input {
  @apply w-full
}
  </style>
